import { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Loader from "components/Loader";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { adminlogin, clearLoginError } from "redux/actions/onboardingAction";
import * as types from "redux/actions/ActionTypes";
import { setBase } from "redux/actions/onboardingAction";

function AdminLogin() {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const loading = useSelector((state) => state.onboardingReducer.loading);
  const error = useSelector((state) => state.onboardingReducer.error);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alertContent = () => (
    <MDTypography variant="body2" color="white">
      {error}
    </MDTypography>
  );
  useEffect(() => {
    dispatch(clearLoginError());
    dispatch(setBase("admin"));
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleLogin = () => {
    const user = {
      email,
      password,
    };
    if (email.trim().length > 3 && password.trim().length > 3)
      dispatch(adminlogin(user, navigate));
  };
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Admin login
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                onChange={(event) => setEmail(event.target.value)}
                type="email"
                label="Email"
                required
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={(event) => setPassword(event.target.value)}
                required
                type="password"
                label="Password"
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={1}>
              <MDButton
                onClick={handleLogin}
                variant="gradient"
                color="info"
                fullWidth
              >
                sign in
              </MDButton>
              {loading && <Loader />}
              {error && (
                <MDBox mt={2} mb={1} textAlign="center">
                  <MDAlert color="error" dismissible>
                    {alertContent("error")}
                  </MDAlert>
                </MDBox>
              )}
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/contact"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Contact Us
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default AdminLogin;
