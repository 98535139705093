import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import team2 from "assets/images/team-2.jpg";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import {navbarIconButton} from "examples/Navbars/DashboardNavbar/styles";
import MDBadge from "components/MDBadge";

export default function DataTable({handleOpenMenu, data, setData, visits}) {
    const [page,
        setPage] = React.useState(0);
    const [rowsPerPage,
        setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+ event.target.value);
        setPage(0);
    };
    React.useEffect(() => {
        setData(visits)
    }, [visits])

    return (
        <paper
            sx={{
            width: '100%',
            overflow: 'hidden'
        }}>
            <TableContainer sx={{
                maxHeight: "100vh"
            }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow
                            sx={{
                            width: 1120,
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <TableCell
                                sx={{
                                flex: 3.5,
                                fontSize: '0.65rem',
                                fontWeight: 700,
                                color: '#7B809A',
                                opacity: 0.7,
                                textTransform: 'uppercase',
                                lineHeight: 1.5
                            }}>Full Name & Email</TableCell>
                            <TableCell
                                sx={{
                                flex: 0.8,
                                textAlign: 'center',
                                fontSize: '0.65rem',
                                fontWeight: 700,
                                color: '#7B809A',
                                opacity: 0.7,
                                textTransform: 'uppercase',
                                lineHeight: 1.5
                            }}>Secret Code</TableCell>
                            <TableCell
                                sx={{
                                flex: 1.3,
                                textAlign: 'left',
                                fontSize: '0.65rem',
                                fontWeight: 700,
                                color: '#7B809A',
                                opacity: 0.7,
                                textTransform: 'uppercase',
                                lineHeight: 1.5
                            }}>Phone</TableCell>
                            <TableCell
                                sx={{
                                flex: 1,
                                textAlign: 'center',
                                fontSize: '0.65rem',
                                fontWeight: 700,
                                color: '#7B809A',
                                opacity: 0.7,
                                textTransform: 'uppercase',
                                lineHeight: 1.5
                            }}>Status</TableCell>
                            <TableCell
                                sx={{
                                flex: 1.1,
                                textAlign: 'left',
                                fontSize: '0.65rem',
                                fontWeight: 700,
                                color: '#7B809A',
                                opacity: 0.7,
                                textTransform: 'uppercase',
                                lineHeight: 1.5
                            }}>Whom to see</TableCell>
                            <TableCell
                                sx={{
                                flex: 0.8,
                                textAlign: 'left',
                                fontSize: '0.65rem',
                                fontWeight: 700,
                                color: '#7B809A',
                                opacity: 0.7,
                                textTransform: 'uppercase',
                                lineHeight: 1.5
                            }}>Time in</TableCell>
                            <TableCell
                                sx={{
                                flex: 1.2,
                                textAlign: 'center',
                                fontSize: '0.65rem',
                                fontWeight: 700,
                                color: '#7B809A',
                                opacity: 0.7,
                                textTransform: 'uppercase',
                                lineHeight: 1.5
                            }}>Date Added</TableCell>
                            <TableCell
                                sx={{
                                flex: 0.8,
                                textAlign: 'center',
                                fontSize: '0.65rem',
                                fontWeight: 700,
                                color: '#7B809A',
                                opacity: 0.7,
                                textTransform: 'uppercase',
                                lineHeight: 1.5
                            }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((visit) => (
                                <TableRow
                                    key={visit.id}
                                    hover
                                    tabIndex={-1}
                                    sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    '&:last-child td, &:last-child th': {
                                        border: 0
                                    }
                                }}>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        sx={{
                                        flex: 3.5,
                                        textAlign: 'left'
                                    }}>
                                        <MDBox
                                            sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            width: '100%',
                                            paddingLeft: '10px'
                                        }}>
                                            <MDAvatar
                                                src={team2}
                                                alt={visit.name}
                                                sx={{
                                                width: '2.25rem',
                                                height: '2.25rem',
                                                fontSize: '0.875rem'
                                            }}/>
                                            <MDBox
                                                sx={{
                                                ml: 2
                                            }}>
                                                <MDTypography
                                                    component="h3"
                                                    sx={{
                                                    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                                                    color: '#344767',
                                                    fontWeight: 600,
                                                    fontSize: '0.875rem',
                                                    letterSpacing: '0.02857rem'
                                                }}>{visit.name}</MDTypography>
                                                <MDTypography
                                                    component='span'
                                                    sx={{
                                                    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                                                    color: '#7B809A',
                                                    fontWeight: 300,
                                                    lineHeight: 1.25,
                                                    letterSpacing: '0.03333em',
                                                    fontSize: '0.75rem'
                                                }}>{visit.address}</MDTypography>
                                            </MDBox>
                                        </MDBox>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                        flex: 0.8,
                                        textAlign: 'center'
                                    }}>
                                        <MDTypography
                                            component='span'
                                            sx={{
                                            fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                                            color: '#7B809A',
                                            fontWeight: 600,
                                            fontSize: '0.75rem',
                                            lineHeight: 1.25,
                                            letterSpacing: '0.03333em'
                                        }}>{visit.secret_code}</MDTypography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                        flex: 1.3,
                                        textAlign: 'left'
                                    }}>
                                        <MDTypography
                                            component="h3"
                                            sx={{
                                            fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                                            color: '#7B809A',
                                            fontWeight: 600,
                                            fontSize: '0.75rem',
                                            lineHeight: 1.25,
                                            letterSpacing: '0.03333em'
                                        }}>{visit.phone}</MDTypography>
                                        <MDTypography
                                            component='span'
                                            sx={{
                                            fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                                            color: '#344767',
                                            fontWeight: 300,
                                            fontSize: '0.75rem',
                                            lineHeight: 1.25,
                                            letterSpacing: '0.03333em'
                                        }}>Purpose: {visit.purpose}</MDTypography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                        flex: 1,
                                        textAlign: 'center'
                                    }}>
                                        <MDBox ml={-1}>
                                            <MDBadge
                                                badgeContent={visit.status === 0
                                                ? "Pending"
                                                : visit.status === 1
                                                    ? "Approved"
                                                    : "Declined"}
                                                color={visit.status === 0
                                                ? "warning"
                                                : visit.status === 1
                                                    ? "success"
                                                    : "error"}
                                                variant="gradient"
                                                size="sm"/>
                                        </MDBox>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                        flex: 1.2,
                                        textAlign: 'left'
                                    }}>
                                        <MDTypography
                                            component="h3"
                                            sx={{
                                            fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                                            color: '#7B809A',
                                            fontWeight: 600,
                                            fontSize: '0.75rem'
                                        }}>{visit.visitinguser}</MDTypography>
                                        <MDTypography
                                            component='span'
                                            sx={{
                                            fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                                            color: '#7B809A',
                                            fontWeight: 300,
                                            fontSize: '0.75rem'
                                        }}>Department: {visit.department}</MDTypography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                        flex: 0.8,
                                        textAlign: 'left'
                                    }}>
                                        <MDTypography
                                            component="h3"
                                            sx={{
                                            fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                                            color: '#7B809A',
                                            fontWeight: 600,
                                            fontSize: '0.75rem'
                                        }}>{visit.sign_in_time}</MDTypography>
                                        <MDTypography
                                            component='span'
                                            sx={{
                                            fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                                            color: '#7B809A',
                                            fontWeight: 300,
                                            fontSize: '0.75rem'
                                        }}>Time Out: {visit.sign_out_time}</MDTypography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                        flex: 1.2,
                                        textAlign: 'center'
                                    }}>
                                        <MDTypography
                                            component="h3"
                                            sx={{
                                            fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
                                            color: '#7B809A',
                                            fontWeight: 600,
                                            fontSize: '0.75rem'
                                        }}>{new Date(visit.createdAt).toDateString()}</MDTypography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                        flex: 0.8,
                                        textAlign: 'center'
                                    }}>
                                        <IconButton
                                            size="small"
                                            disableRipple
                                            color="inherit"
                                            sx={navbarIconButton}
                                            onClick={(event) => handleOpenMenu(event, visit.id, visit.status)}
                                            style={{
                                            color: '#344767'
                                        }}
                                            aria-controls="notification-menu"
                                            aria-haspopup="true"
                                            variant="contained">
                                            <Icon>more_vert</Icon>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}/>

        </paper>
    );
}
