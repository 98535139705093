import { useState, useEffect } from "react";

import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

import { useMaterialUIController, setOpenConfigurator } from "context";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import { addVisit } from "redux/actions/userActions";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function InviteGuest() {
  const [controller, dispatch] = useMaterialUIController();
  const dispatchAction = useDispatch();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [purpose, setPurpose] = useState("");
  const [idMode, setIDMode] = useState("");
  const [schedule, setSchedule] = useState(false);
  const [scheduledate, setScheduledate] = useState("00-00-0000");
  const [addresponse, setAddresponse] = useState(false);
  const loading = useSelector((state) => state.userReducer.loading);
  const secretcode = useSelector(
    (state) => state.userReducer?.response?.secret_code || ""
  );
  const { openConfigurator, darkMode } = controller;
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    window.addEventListener("resize", handleDisabled);

    handleDisabled();

    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const handleCloseConfigurator = () => {
    setAddresponse(false);
    setOpenConfigurator(dispatch, false);
  };
  const handleReset = () => {
    setName("");
    setPhone("");
    setAddress("");
    setPurpose("");
    setScheduledate("");
    setSchedule(false);
  };

  const handleSubmit = () => {
    const user = {
      name,
      phone,
      email: "NA",
      address,
      purpose,
      type: schedule,
      schedule_date: scheduledate,
      idMode,
    };
    if (name && phone && purpose) {
      dispatchAction(addVisit(user));
      setTimeout(() => {
        setAddresponse(true);
      }, 2000);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid container spacing={6}>
        <Grid item xs={5}>
          <MDBox pt={4} pb={0.5} px={3}>
            <MDBox>
              <MDTypography variant="h5">Add New visitor</MDTypography>
              <MDTypography variant="body2" color="text">
                Expecting a visitor?
                <br />
                fill in necessary details.
              </MDTypography>
            </MDBox>
          </MDBox>

          <Divider />

          <MDBox
            sx={{ display: "flex", flexDirection: "column" }}
            pt={0.5}
            pb={3}
            px={3}
          >
            {!addresponse ? (
              <>
                <TextField
                  required
                  id="outlined-required"
                  label="Full Name"
                  sx={{ marginBottom: 2 }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  required
                  id="outlined-required"
                  label="Phone"
                  sx={{ marginBottom: 2 }}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <TextField
                  required
                  id="outlined-required"
                  label="Address"
                  sx={{ marginBottom: 2 }}
                  value={address}
                  multiline
                  rows={3}
                  onChange={(e) => setAddress(e.target.value)}
                />
                <TextField
                  required
                  id="outlined-required"
                  sx={{ marginBottom: 2 }}
                  label="Purpose"
                  value={purpose}
                  onChange={(e) => setPurpose(e.target.value)}
                />

                <MDBox m={1} fullWidth>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Mode of Identification
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={idMode}
                      fullWidth
                      sx={{ height: 45, width: "100%" }}
                      onChange={(e) => setIDMode(e.target.value)}
                    >
                      <MenuItem value={"Work ID"}>Work ID</MenuItem>
                      <MenuItem value={"Driver's license"}>
                        Driver's license
                      </MenuItem>
                      <MenuItem value={"National ID"}>National ID</MenuItem>
                      <MenuItem value={"International Passport"}>
                        International PassportD
                      </MenuItem>
                      <MenuItem value={"Voters card"}>Voters card</MenuItem>
                      <MenuItem value={"None"}>None</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>

                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={3}
                  lineHeight={1}
                >
                  <MDTypography variant="h6">Schedule</MDTypography>

                  <Switch
                    checked={schedule}
                    onChange={() => setSchedule((preSchedule) => !preSchedule)}
                  />
                </MDBox>
                {schedule && (
                  <TextField
                    id="outlined-required"
                    type="date"
                    value={scheduledate}
                    onChange={(e) => setScheduledate(e.target.value)}
                    sx={{ marginTop: 2 }}
                  />
                )}
                <MDBox mt={3} lineHeight={1}>
                  <MDBox
                    sx={{
                      display: "flex",
                      mt: 2,
                      mr: 1,
                    }}
                  >
                    <MDBox sx={{ mx: 1, width: "8rem", minWidth: "8rem" }}>
                      <MDButton
                        color="light"
                        variant="gradient"
                        onClick={handleReset}
                        //   disabled={disabled}
                        fullWidth
                      >
                        Reset
                      </MDButton>
                    </MDBox>
                    <MDButton
                      color="info"
                      variant="gradient"
                      onClick={handleSubmit}
                      //  disabled={disabled}
                      fullWidth
                    >
                      Submit
                    </MDButton>
                  </MDBox>
                  {loading && <Loader />}
                </MDBox>
                <Divider />
              </>
            ) : (
              <>
                <MDBox
                  display="flex"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                  lineHeight={1}
                  mb={0.5}
                >
                  <MDTypography variant="h6">Congratulations!</MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  lineHeight={1}
                >
                  <MDTypography variant="h6">
                    Visit Code: {secretcode}
                  </MDTypography>
                  <MDBox>
                    <MDTypography
                      sx={{ fontWeight: "normal", fontSize: 12 }}
                      variant="h6"
                    >
                      Send via SMS
                    </MDTypography>
                    <MDTypography
                      sx={{ fontWeight: "normal", fontSize: 12 }}
                      variant="h6"
                    >
                      Copy
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox mt={2} textAlign="center">
                  <MDBox display="flex" justifyContent="center">
                    <MDBox mr={1.5}>
                      <MDButton
                        component={Link}
                        href="//twitter.com/intent/tweet?text=This is your visit code"
                        target="_blank"
                        rel="noreferrer"
                        color="dark"
                      >
                        <TwitterIcon />
                        &nbsp; Tweet
                      </MDButton>
                    </MDBox>
                    <MDButton
                      component={Link}
                      href="https://www.facebook.com/sharer/sharer.php?u=This is your visit code"
                      target="_blank"
                      rel="noreferrer"
                      color="dark"
                    >
                      <FacebookIcon />
                      &nbsp; Share
                    </MDButton>
                  </MDBox>
                </MDBox>
              </>
            )}
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default InviteGuest;
