import * as types from "../actions/ActionTypes";

const onboardingReducer = (
  state = { logstatus: false, type: "user", basetype: "user" },
  action
) => {
  switch (action.type) {
    case types.SIGN_UP_START:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        success: null,
      });
    case types.SIGN_UP_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        success:
          "Your account has been registered, please check your email to verify",
        response: action.payload,
      });
    case types.SIGN_UP_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
        success: null,
      });
    case types.CHANGE_PASSWORD_START:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        success: null,
      });
    case types.CHANGE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        success: action.payload,
      });
    case types.CHANGE_PASSWORD_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
        success: null,
      });
    case types.GET_COMPANYID_START:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        companyid: null,
      });
    case types.GET_COMPANYID_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        companyid: action.payload,
      });
    case types.GET_COMPANYID_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
        companyid: null,
      });
    case types.RESET_START:
      return Object.assign({}, state, {
        loading: true,
        error: null,
        success: null,
      });
    case types.RESET_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        success:
          "Your password reset instructions has been sent to your email.",
        response: action.payload,
      });
    case types.RESET_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
        success: null,
      });
    case types.VERIFY_SUCCESS:
      return Object.assign({}, state, {
        token: action.payload.token,
        logstatus: true,
        name: action.payload.name,
        email: action.payload.email,
      });
    case types.LOGIN_START:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });
    case types.SET_BASE_SUCCESS:
      return Object.assign({}, state, {
        basetype: action.payload,
      });
    case types.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        logstatus: true,
        token: action.payload.token,
        email: action.payload.email,
        displayname: action.payload.displayname,
        type: action.payload.type,
      });
    case types.GET_STATS_SUCCESS:
      return Object.assign({}, state, {
        dashboard: action.payload,
      });

    case types.LOGIN_FAILED:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
        success: null,
      });
    case types.CLEAR_LOGIN:
      return Object.assign({}, state, {
        loading: false,
        error: null,
        success: null,
      });

    case types.LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        logstatus: false,
        token: null,
        email: null,
        displayname: null,
      });
    case types.GET_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        profile: action.payload,
      });

    case types.ADMIN_LOGIN_START:
      return Object.assign({}, state, {
        loading: true,
        error: null,
      });

    case types.ADMIN_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        logstatus: true,
        token: action.payload.token,
        email: action.payload.email,
        displayname: action.payload.displayname,
        type: action.payload.type,
      });
    case types.ADMIN_LOGIN_END:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });
    case types.ACTION_CLEAR:
      return Object.assign({}, state, {
        error: null,
        loading: false,
        success: null,
      });
    case types.RIGHTOFFERACCEPT_SUCCESS:
      return Object.assign({}, state, {
        RightOfferAccepted: action.payload.rightofferaccepted,
      });
    case types.COMPLETE_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        currentplan: action.payload.currentplan,
      });
    case types.OPEN_NAV:
      return Object.assign({}, state, {
        sidebar: true,
      });
    case types.CLOSE_NAV:
      return Object.assign({}, state, {
        sidebar: false,
      });
    default:
      return state;
  }
};
export default onboardingReducer;
