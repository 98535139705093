// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Loader from "components/Loader";
import MDAlert from "components/MDAlert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changepassword } from "redux/actions/onboardingAction";
import { clearMessages } from "redux/actions/uiActions";

function ProfilesList({ title, shadow }) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.onboardingReducer?.loading);
  const error = useSelector((state) => state.onboardingReducer.error);
  const success = useSelector((state) => state.onboardingReducer?.success);
  const [oldpassword, setOldPassword] = useState();
  const [newpassword, setNewPassword] = useState();
  const [newpassword2, setNewPassword2] = useState();
  const alertContent = (error) => (
    <MDTypography variant="body2" color="white">
      {String(error)}
    </MDTypography>
  );
  React.useEffect(() => {
    dispatch(clearMessages());
  }, []);
  const handleChangepassword = () => {
    const user = {
      oldpassword,
      newpassword,
      newpassword2,
    };
    dispatch(changepassword(user));
  };
  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          mb={2}
        >
          <MDInput
            required
            type="password"
            id="standard-error-helper-text"
            label="Old Password"
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </MDBox>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          mb={2}
        >
          <MDInput
            required
            id="standard-error-helper-text"
            type="password"
            label="New Password"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </MDBox>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          <MDInput
            required
            id="standard-error-helper-text"
            type="password"
            label="New Password Again"
            onChange={(e) => setNewPassword2(e.target.value)}
          />
        </MDBox>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          mt={2}
          mx={5}
        >
          <MDButton
            color="info"
            variant="gradient"
            onClick={handleChangepassword}
            //  disabled={disabled}
          >
            Update
          </MDButton>
          {loading && <Loader />}
          {error && (
            <MDBox mt={2} mb={1} textAlign="center">
              <MDAlert color="error" dismissible>
                {alertContent(error)}
              </MDAlert>
            </MDBox>
          )}
          {success && (
            <MDBox mt={2} mb={1} textAlign="center">
              <MDAlert color="success" dismissible>
                {alertContent(success)}
              </MDAlert>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
ProfilesList.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
};

export default ProfilesList;
