import React from 'react'
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

const DateRangePickerComp = ({setData, visits}) => {
    const [start,
        setStart] = React.useState('')
    const [end,
        setEnd] = React.useState('')

    const handlePickStart = (event) => {
        setStart(event.target.value)
    }

    const handlePickEnd = (event) => {
        setEnd(event.target.value)
        const newArr = visits.filter(item => new Date(item.createdAt).toDateString() === "Wed Mar 02 2022")
        setData(newArr)
    }

    const startDate = new Date(start)
    const endDate = new Date(end)
    const expo = 1000*60*60*24
    const diff = (endDate - startDate) / expo.toExponential()
    const dates = Array.from({
        length: diff + 1
    }, (_, i) => {
        const date = new Date()
        date.setDate(startDate.getDate() + i)
        const newDate = date
        .toDateString()
        .split(', ')
        return `${newDate}`
    })
    console.log(dates.map((date) => date))
    const dataDate = visits.map(item => new Date(item.createdAt).toDateString())
    const matches = dates.includes(dataDate)
    console.log(matches)
    return (
        <MDBox>
            <MDInput
                max={end}
                onChange={handlePickStart}
                type="date"
                label="Date from"
                />
            <MDInput
                sx={{
                mr: 1
            }}
                type="date"
                label="Date to"
                min={start}
                onChange={handlePickEnd}
                />
        </MDBox>
    )
}

export default DateRangePickerComp

