import { useState, useEffect } from "react";

import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader";
import { addVisit } from "redux/actions/userActions";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

import { useMaterialUIController, setOpenConfigurator } from "context";
import { fontSize } from "@mui/system";
import { confirmVisit, clearConfirm } from "redux/actions/adminActions";
import { checkOutVisit } from "redux/actions/adminActions";
import { approveVisit } from "redux/actions/adminActions";
import { declineVisit } from "redux/actions/adminActions";

function VerifyVisit() {
  const [controller, dispatch] = useMaterialUIController();
  const dispatchAction = useDispatch();
  const { openConfigurator, darkMode } = controller;
  const [disabled, setDisabled] = useState(false);
  const [visitcode, setVisitCode] = useState("");
  const [addresponse, setAddresponse] = useState(false);
  const loading = useSelector((state) => state.adminReducer?.loading);
  const error = useSelector((state) => state.adminReducer?.error);
  const data = useSelector((state) => state.adminReducer?.response);
  const alertContent = (error) => (
    <MDTypography variant="body2" color="white">
      {String(error)}
    </MDTypography>
  );

  useEffect(() => {
    dispatchAction(clearConfirm());
  }, []);

  useEffect(() => {
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    window.addEventListener("resize", handleDisabled);
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const handleCloseConfigurator = () => {
    setAddresponse(false);
    setOpenConfigurator(dispatch, false);
  };

  const handleReset = () => {
    setVisitCode("");
  };

  const handleDecline = (id) => {
    const visit = {
      id,
    };
    dispatchAction(declineVisit(visit));
    handleCloseConfigurator();
    dispatchAction(clearConfirm());
  };

  const handleCheckout = (id) => {
    const visit = {
      id,
    };
    dispatchAction(checkOutVisit(visit));
    handleCloseConfigurator();
    dispatchAction(clearConfirm());
  };

  const handleApprove = (id) => {
    const visit = {
      id,
    };
    dispatchAction(approveVisit(visit));
    handleCloseConfigurator();
    dispatchAction(clearConfirm());
  };

  const handleSearch = () => {
    const payload = {
      visitcode,
    };
    if (visitcode.length > 0) {
      dispatchAction(confirmVisit(payload));
    }
  };

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Confirm a visitor</MDTypography>
          <MDTypography variant="body2" color="text">
            Expecting a visitor?
            <br />
            fill in necessary details.
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>

      <Divider />

      <MDBox
        sx={{ display: "flex", flexDirection: "column" }}
        pt={0.5}
        pb={3}
        px={3}
      >
        {!data ? (
          <>
            <TextField
              required
              id="outlined-required"
              label="Enter visit code"
              value={visitcode}
              onChange={(e) => setVisitCode(e.target.value)}
            />
            <MDBox mt={3} lineHeight={1}>
              <MDBox
                sx={{
                  display: "flex",
                  mr: 1,
                }}
              >
                <MDBox sx={{ mx: 1, width: "8rem", minWidth: "8rem" }}>
                  <MDButton
                    color="light"
                    variant="gradient"
                    onClick={handleReset}
                    // disabled={disabled}
                    fullWidth
                  >
                    Reset
                  </MDButton>
                </MDBox>

                <MDButton
                  color="dark"
                  variant="gradient"
                  onClick={handleSearch}
                  //  disabled={disabled}
                  fullWidth
                >
                  Search
                </MDButton>
              </MDBox>
              {loading && <Loader />}
              {error && (
                <MDBox mt={2} mb={1} textAlign="center">
                  <MDAlert color="error" dismissible>
                    {alertContent(error)}
                  </MDAlert>
                </MDBox>
              )}
            </MDBox>
          </>
        ) : (
          <>
            <Divider />
            {data.status === 1 ? (
              <MDBox>
                <MDBox
                  display="flex"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                  lineHeight={1}
                  mb={0.5}
                >
                  <MDBox>
                    <MDTypography variant="h5">Checkout!</MDTypography>
                    <MDTypography variant="h6">
                      Visitor information found!
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  lineHeight={1}
                >
                  <MDBox
                    sx={{
                      marginTop: 2,
                      borderWidth: 1,
                      borderColor: "gray",
                      padding: 2,
                    }}
                  >
                    <MDTypography
                      sx={{ fontSize: 14, fontWeight: "normal" }}
                      variant="h6"
                    >
                      Exit Code:
                    </MDTypography>
                    <MDTypography variant="h6">{data.secret_code}</MDTypography>
                    <MDTypography
                      sx={{ fontSize: 14, fontWeight: "normal" }}
                      variant="h6"
                    >
                      Staff Visited:
                    </MDTypography>
                    <MDTypography variant="h6">
                      {data.visitinguser}
                    </MDTypography>

                    <MDTypography
                      sx={{ fontSize: 14, fontWeight: "normal" }}
                      variant="h6"
                    >
                      Purpose:
                    </MDTypography>
                    <MDTypography variant="h6">{data.purpose}</MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox mt={2} textAlign="center">
                  <MDBox display="flex" justifyContent="center">
                    <MDButton
                      onClick={() => handleCheckout(data.id)}
                      color="success"
                    >
                      Check out
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            ) : (
              <MDBox>
                <MDBox
                  display="flex"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                  lineHeight={1}
                  mb={0.5}
                >
                  <MDBox>
                    <MDTypography variant="h5">Success!</MDTypography>
                    <MDTypography variant="h6">
                      Visitor information found!
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  lineHeight={1}
                >
                  <MDBox
                    sx={{
                      marginTop: 2,
                      borderWidth: 1,
                      borderColor: "gray",
                      padding: 2,
                    }}
                  >
                    <MDTypography
                      sx={{ fontSize: 14, fontWeight: "normal" }}
                      variant="h6"
                    >
                      Visit Code:
                    </MDTypography>
                    <MDTypography variant="h6">{data.secret_code}</MDTypography>
                    <MDTypography
                      sx={{ fontSize: 14, fontWeight: "normal" }}
                      variant="h6"
                    >
                      Staff Visiting:
                    </MDTypography>
                    <MDTypography variant="h6">
                      {data.visitinguser}
                    </MDTypography>
                    <MDTypography
                      variant="h6"
                      sx={{ fontSize: 14, fontWeight: "normal" }}
                    >
                      Address:
                    </MDTypography>
                    <MDTypography sx={{ fontSize: 12 }} variant="h6">
                      {data.address}
                    </MDTypography>
                    <MDTypography
                      variant="h6"
                      sx={{ fontSize: 14, fontWeight: "normal" }}
                    >
                      Phone Number:
                    </MDTypography>
                    <MDTypography variant="h6">{data.phone}</MDTypography>
                    <MDTypography
                      sx={{ fontSize: 14, fontWeight: "normal" }}
                      variant="h6"
                    >
                      Purpose:
                    </MDTypography>
                    <MDTypography variant="h6">{data.purpose}</MDTypography>
                    <MDTypography
                      sx={{ fontSize: 14, fontWeight: "normal" }}
                      variant="h6"
                    >
                      Mode of Verification:
                    </MDTypography>
                    <MDTypography variant="h6">
                      {data.verification_mode}
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox mt={2} textAlign="center">
                  <MDBox display="flex" justifyContent="center">
                    <MDBox mr={1.5}>
                      <MDButton
                        onClick={() => handleDecline(data.id)}
                        color="error"
                      >
                        decline
                      </MDButton>
                    </MDBox>
                    <MDButton
                      onClick={() => handleApprove(data.id)}
                      color="success"
                    >
                      Accept
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            )}
          </>
        )}
      </MDBox>
    </ConfiguratorRoot>
  );
}

export default VerifyVisit;
