import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem"; // Soft UI Dashboard React components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// Data
import projectsTableData from "layouts/companies/data/projectsTableData";
import { getCompanies } from "redux/actions/adminActions";
import { SettingsInputComponent } from "@mui/icons-material";
import {
  deleteCompany,
  suspendCompany,
  addCompany,
  updateCompany,
} from "redux/actions/adminActions";

function Companies() {
  const handleOpenMenu = (event, id, status) => {
    setCurrentID(id);
    setCurrentStatus(status);
    setOpenMenu(event.currentTarget);
  };
  const {
    columns: pColumns,
    rows: pRows,
    id,
  } = projectsTableData(handleOpenMenu);
  const companies = useSelector((state) => state.adminReducer?.companies);

  const [open, setOpen] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showSuspend, setShowSuspend] = React.useState(false);
  const [edit, showEdit] = React.useState(false);
  const [currentID, setCurrentID] = React.useState();
  const [currentStatus, setCurrentStatus] = React.useState();

  const [companyName, setCompanyName] = React.useState("");
  const [companyEmail, setCompanyEmail] = React.useState("");
  const [companyPhone, setCompanyPhone] = React.useState("");
  const [companyAddress, setCompanyAddress] = React.useState("");
  const [companySubdomain, setCompanySubdomain] = React.useState("");
  const [companyCode, setCompanyCode] = React.useState("");

  const handleCloseMenu = () => setOpenMenu(false);
  const [scroll, setScroll] = React.useState("paper");

  const dispatchAction = useDispatch();

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  const handleShowEdit = () => {
    handleCloseMenu();
    const companyedit = companies.filter(
      (company) => company.id === currentID
    )[0];
    setCompanyName(companyedit.name);
    setCompanyEmail(companyedit.email);
    setCompanyPhone(companyedit.phone);
    setCompanyAddress(companyedit.address);
    setCompanyCode(companyedit.code);
    setCompanySubdomain(companyedit.subdomain);
    showEdit(true);
  };

  const handleCloseEdit = () => {
    showEdit(false);
  };
  const handleCloseDelete = () => {
    setShowDelete(false);
  };
  const handleShowDelete = () => {
    handleCloseMenu();
    setShowDelete(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleShowSuspend = () => {
    handleCloseMenu();
    setShowSuspend(true);
  };
  const handleSuspendClose = () => {
    setShowSuspend(false);
  };
  const handleDelete = (id) => {
    dispatchAction(deleteCompany(id));
    handleCloseDelete();
  };

  const handleSuspend = (id) => {
    const newstatus = currentStatus === 2 ? 1 : 2;
    const status = {
      newstatus,
      id,
    };
    dispatchAction(suspendCompany(status));
    handleSuspendClose();
  };

  const handleSubmit = () => {
    const company = {
      email: companyEmail,
      address: companyAddress,
      name: companyName,
      phone: companyPhone,
      subdomain: companySubdomain,
    };
    dispatchAction(addCompany(company));
    handleClose();
  };

  const handleUpdate = () => {
    const company = {
      id: currentID,
      email: companyEmail,
      address: companyAddress,
      name: companyName,
      phone: companyPhone,
      subdomain: companySubdomain,
    };
    dispatchAction(updateCompany(company));
    handleCloseEdit();
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    dispatchAction(getCompanies());
  }, []);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <DashboardLayout>
      <Dialog
        open={showSuspend}
        onClose={handleSuspendClose}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {currentStatus === 2 ? "Activate" : "Suspend"} Company?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm that you want to{" "}
            {currentStatus === 2 ? "activate" : "suspend"} this company.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuspendClose}>Cancel</Button>
          <Button onClick={() => handleSuspend(currentID)} autoFocus>
            {currentStatus === 2 ? "Activate" : "Suspend"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showDelete}
        onClose={handleCloseDelete}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Company?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm that you want to delete this company, this action
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button onClick={() => handleDelete(currentID)} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        id="simple-menu"
        anchorEl={openMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleShowEdit(currentID)}>Edit</MenuItem>
        <MenuItem onClick={handleShowSuspend}>
          {currentStatus === 2 ? "Activate" : "Suspend"}
        </MenuItem>
        <MenuItem onClick={handleShowDelete}>Delete</MenuItem>
      </Menu>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Add New Company</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <MDBox pt={2}>
            <MDBox
              pb={2}
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "55ch" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <TextField
                required
                id="standard-error-helper-text"
                label="Company Name"
                variant="standard"
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <TextField
                required
                id="standard-error-helper-text"
                label="Email"
                onChange={(e) => setCompanyEmail(e.target.value)}
                variant="standard"
              />

              <TextField
                required
                id="standard-error-helper-text"
                label="Phone"
                onChange={(e) => setCompanyPhone(e.target.value)}
                variant="standard"
              />
              <TextField
                required
                id="standard-error-helper-text"
                label="Address"
                multiline
                onChange={(e) => setCompanyAddress(e.target.value)}
                rows={4}
                variant="standard"
              />
              <TextField
                required
                id="standard-error-helper-text"
                label="Subdomain"
                onChange={(e) => setCompanySubdomain(e.target.value)}
                variant="standard"
              />
            </MDBox>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {!edit ? (
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    List of Registered Companies
                  </MDTypography>
                </MDBox>
                <MDBox pt={2}>
                  <MDBox
                    pb={2}
                    pr={2}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <MDButton
                      onClick={handleClickOpen("paper")}
                      variant="gradient"
                      color="dark"
                    >
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp; Add New Company
                    </MDButton>
                  </MDBox>
                  <DataTable
                    table={{ columns: pColumns, rows: pRows }}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Edit Company
                  </MDTypography>
                </MDBox>
                <MDBox pt={3} pb={1} px={3}>
                  <MDBox component="form" role="form">
                    <MDBox pb={1}>
                      <MDInput
                        required
                        id="standard-error-helper-text"
                        label="Company Name"
                        value={companyName}
                        fullWidth
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </MDBox>
                    <MDBox pb={1}>
                      <MDInput
                        required
                        id="standard-error-helper-text"
                        label="Email"
                        value={companyEmail}
                        fullWidth
                        onChange={(e) => setCompanyEmail(e.target.value)}
                      />
                    </MDBox>

                    <MDBox pb={1}>
                      <MDInput
                        required
                        id="standard-error-helper-text"
                        label="Phone"
                        value={companyPhone}
                        fullWidth
                        onChange={(e) => setCompanyPhone(e.target.value)}
                      />
                    </MDBox>
                    <MDBox pb={1}>
                      <MDInput
                        required
                        id="standard-error-helper-text"
                        label="Address"
                        multiline
                        rows={4}
                        fullWidth
                        onChange={(e) => setCompanyAddress(e.target.value)}
                        value={companyAddress}
                      />
                    </MDBox>

                    <MDBox pb={1}>
                      <MDInput
                        required
                        id="outlined-required"
                        label="Company Code"
                        fullWidth
                        value={companyCode}
                        onChange={(e) => setCompanyCode(e.target.value)}
                      />
                    </MDBox>
                    <MDBox pb={1}>
                      <MDInput
                        id="outlined-disabled"
                        label="Subdomain"
                        fullWidth
                        value={companySubdomain}
                        onChange={(e) => setCompanySubdomain(e.target.value)}
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>
                <MDBox
                  pb={2}
                  pt={2}
                  pr={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <MDButton
                    onClick={handleCloseEdit}
                    variant="gradient"
                    sx={{ marginRight: 1 }}
                    color="light"
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    onClick={handleUpdate}
                    variant="gradient"
                    color="dark"
                  >
                    <Icon sx={{ fontWeight: "bold" }}>check</Icon>
                    &nbsp; Update
                  </MDButton>
                </MDBox>
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Companies;
