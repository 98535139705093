/* eslint-disable react/prop-types */
import * as React from "react";
import { useSelector } from "react-redux";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem"; // Soft UI Dashboard React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import MDBadge from "components/MDBadge";
import IconButton from "@mui/material/IconButton";
import NotificationItem from "examples/Items/NotificationItem";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
// Images
import LogoAsana from "assets/images/small-logos/logo-asana.svg";
import logoGithub from "assets/images/small-logos/github.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";

export default function data(handleOpenMenu) {
  //const handleCloseMenu = () => setOpenMenu(false);
  const companies = useSelector((state) => state.adminReducer?.companies);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    setData(
      companies.map((company) => ({
        Company: (
          <Company
            image={LogoAsana}
            name={company.name}
            address={company.address}
          />
        ),
        contact: <Job title={company.phone} description={company.email} />,
        details: (
          <OtherDetails subdomain={company.subdomain} code={company.code} />
        ),
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={
                company.status === 0
                  ? "Pending"
                  : company.status === 1
                  ? "Active"
                  : "Suspended"
              }
              color={
                company.status === 0
                  ? "warning"
                  : company.status === 1
                  ? "success"
                  : "error"
              }
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        completion: <Progress color="info" value={company.userallocation} />,
        action: (
          <>
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={navbarIconButton}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={(event) =>
                handleOpenMenu(event, company.id, company.status)
              }
            >
              <Icon>more_vert</Icon>
            </IconButton>
          </>
        ),
      }))
    );
  }, []);

  const Company = ({ image, name, address = "Somewhere in Lagos" }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" variant="rounded" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{address}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );
  const OtherDetails = ({ subdomain, code }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {subdomain}
      </MDTypography>
      <MDTypography variant="caption">Code: {code}</MDTypography>
    </MDBox>
  );
  return {
    columns: [
      {
        Header: "Company Name",
        accessor: "Company",
        width: "30%",
        align: "left",
      },
      { Header: "Contact", accessor: "contact", align: "left" },
      { Header: "Subdomain", accessor: "details", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "User Allocation", accessor: "completion", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: data,
  };
}
